/* eslint-disable import/no-extraneous-dependencies */
// reducers/index.js
import { combineReducers } from 'redux';

import userSlice from './userSlice';

const rootReducer = combineReducers({
  userInfo: userSlice,
  // Add other reducers here
});

export default rootReducer;
