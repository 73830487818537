import React, { useEffect, useState } from 'react';

const ringtone = new Audio('/ringtone.mp3'); // Replace with your actual sound file
ringtone.loop = true; // Ensure the sound loops continuously

export default function IncomingCallHandler({ incomingCall }) {
  useEffect(() => {
    if (incomingCall) {
      ringtone
        .play()
        .catch((err) => console.error('Error playing sound:', err));
    } else {
      ringtone.pause();
      ringtone.currentTime = 0; // Reset audio to start
    }
  }, [incomingCall]);

  return null; // No UI needed for this component
}
