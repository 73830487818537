import {
  ArrowBack,
  Call,
  Delete,
  MoreVert,
  Videocam,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import UserTyping from './userTyping';
import MessageRenderer from './messageRenderer';
import ChatComposer from './ChatComposer';
import { useTheme } from '@emotion/react';
import EmojiContainer from './EmojiContainer';
import PublicProfilePage from '../publicProfilePage';
import UserBadge from '../userBadge';

function ChatInterfaceContainer({
  userData,
  selectedChat,
  setSelectedChat,
  setShowLeft,
  setSelectedProfile,
  setShowProfile,
  isFollowing,
  handleMessageRequest,
  handleOpenCallConfirm,
  handelDeleteAll,
  chatContainerRef,
  isTyping,
  handleClick,
  messages,
  userInfo,
  handleDeleteChat,
  setEditingMessage,
  editingMessage,
  replyEnable,
  setReplyEnable,
  setEditEnable,
  setNewMessage,
  handleQuicReply,
  editEnable,
  loading,
  handleSocketTyping,
  newMessage,
  handleSocketEmit,
  isChatDisabled,
  setShowEmojiList,
  handleAttachClick,
  fileInputRef,
  handleFileChange,
  showLeft,
  showEmojiList,
  videoCallEnable,
  setVideoCallEnable,
  showProfile,
  selectedProfile,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card
      style={{
        height: '100%',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#171d26',
      }}
    >
      {selectedChat ? (
        <>
          <CardContent
            style={{
              padding: '10px 20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#121820',
            }}
          >
            {isMobile && (
              <IconButton
                onClick={() => {
                  setSelectedChat(null);
                  setShowLeft(true);
                }}
              >
                <ArrowBack />
              </IconButton>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                onClick={() => {
                  setSelectedProfile(selectedChat);
                  setShowProfile(true);
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: '#121820',
                    marginRight: 12,
                  }}
                  src={
                    selectedChat?.chat?.defaultRoomName
                      ? `https://soapboxapi.megahoot.net/profile-pictures/${selectedChat?.chat?.channelLogo}`
                      : `https://soapboxapi.megahoot.net/profile-pictures/${selectedChat?.profilePic}`
                  }
                >
                  {selectedChat?.chat?.defaultRoomName?.slice(0, 1) ||
                    selectedChat.name?.slice(0, 1)}
                </Avatar>
              </Box>
              <Box
                style={{
                  textDecoration: 'none',
                  color: '#fff',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Typography
                  variant="h6"
                  style={{ fontWeight: 600 }}
                  onClick={() => {
                    setSelectedProfile(selectedChat);
                    setShowProfile(true);
                  }}
                >
                  {selectedChat?.chat?.defaultRoomName || selectedChat.name}{' '}
                  <UserBadge userData={userData} />
                </Typography>
                {!isFollowing && !selectedChat?.chat?.defaultRoomName && (
                  <Button
                    variant="outlined"
                    onClick={handleMessageRequest}
                    sx={{ ml: '10px' }}
                    size="small"
                  >
                    Message Request
                  </Button>
                )}
              </Box>
            </div>
            <div>
              {showLeft ? (
                <>
                  {!selectedChat?.chat?.defaultRoomName && (
                    <>
                      <IconButton
                        onClick={() => handleOpenCallConfirm('audio')}
                      >
                        <Call style={{ color: '#fff' }} color="#fff" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleOpenCallConfirm('video')}
                      >
                        <Videocam style={{ color: '#fff' }} color="#fff" />
                      </IconButton>
                    </>
                  )}
                  <IconButton onClick={() => handelDeleteAll('video')}>
                    <Delete style={{ color: '#fff' }} color="#fff" />
                  </IconButton>
                </>
              ) : null}
              <IconButton onClick={handleClick}>
                <MoreVert style={{ color: '#fff' }} />
              </IconButton>
            </div>
          </CardContent>

          <div
            ref={chatContainerRef}
            style={{
              flex: 1,
              overflowY: 'auto',
              padding: '20px 20px 0 20px',
              backgroundColor: '#171d26',
              display: 'flex',
              flexDirection: 'column-reverse',
              width: '100%',
              marginBottom: '150px',
            }}
          >
            <UserTyping isTyping={isTyping} />
            <MessageRenderer
              messages={messages}
              handleDeleteChat={handleDeleteChat}
              setEditingMessage={setEditingMessage}
              editingMessage={editingMessage}
              replyEnable={replyEnable}
              editEnable={editEnable}
              setEditEnable={setEditEnable}
              setReplyEnable={setReplyEnable}
              setNewMessage={setNewMessage}
              handleQuicReply={handleQuicReply}
              loading={loading}
              userInfo={userInfo}
            />
          </div>
          <ChatComposer
            showLeft={showLeft}
            replyEnable={replyEnable}
            setReplyEnable={setReplyEnable}
            editingMessage={editingMessage}
            setEditingMessage={setEditingMessage}
            handleDeleteChat={handleDeleteChat}
            editEnable={editEnable}
            setEditEnable={setEditEnable}
            handleSocketTyping={handleSocketTyping}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            handleSocketEmit={handleSocketEmit}
            isChatDisabled={isChatDisabled}
            setShowEmojiList={setShowEmojiList}
            handleAttachClick={handleAttachClick}
            fileInputRef={fileInputRef}
            handleFileChange={handleFileChange}
          />
        </>
      ) : null}

      <EmojiContainer
        showEmojiList={showEmojiList}
        setNewMessage={setNewMessage}
        setShowEmojiList={setShowEmojiList}
      />

      <Dialog
        maxWidth="xl"
        open={showProfile}
        fullWidth={true}
        onClose={() => setShowProfile(false)}
      >
        <PublicProfilePage
          username={selectedProfile?.chatFrom}
          isChannel={!!selectedChat?.chat?.defaultRoomName}
          ChannelRoomName={selectedChat?.chat?.defaultRoomName}
        />
      </Dialog>
    </Card>
  );
}

export default ChatInterfaceContainer;
