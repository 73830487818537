/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  useMediaQuery,
  Avatar,
  Typography,
  Dialog,
  Button,
} from '@mui/material';
import LeftPanel from './LeftPanel/LeftPanel'; // Import Left Panel
import axios from 'axios';
import { useRouter } from '../../components/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { setChatDataList } from '../../redux/reducers/userSlice';
import ChatRoom from './chatRoom/chatroom';
import DiscoverScreen from './discoverScreen';
import Feeds from './feeds';
import DashboardHeader from './header';
import PublicProfilePage from './publicProfilePage';
import socket from './socketConnector';
import Email from './email';
import MiniProgramsCatalogue from './miniPrograms/miniProgramsCatalogue';
import ChannelChat from './channel/channels';
import CreatePostView from './post/createPost';
import IframeLoader from './iframeLoader';
import kurentoUtils from 'kurento-utils';
import VideoCallPlugin from './test-calls/sampleCall';
import IncomingCallHandler from './test-calls/ringing';
import Draggable from 'react-draggable';
import { toast } from 'react-toastify';
import {
  connectWebSocket,
  sendMessage,
} from '../../redux/reducers/websocketService';
const NOT_REGISTERED = 0;
const REGISTERING = 1;
const REGISTERED = 2;

const NO_CALL = 0;
const PROCESSING_CALL = 1;
const IN_CALL = 2;
const CALLING = 3;
const videoConstraints = {
  deviceId: 'user',
  frameRate: {
    min: 1,
    ideal: 15,
    max: 30,
  },
  width: {
    min: 640,
    ideal: 1280,
    max: 1280,
  },
  height: {
    min: 360,
    ideal: 720,
    max: 720,
  },
};
function Dashboard() {
  const router = useRouter();
  const dispatch = useDispatch();
  const { userInfo, chatListData } = useSelector((state) => state.userInfo);
  const [videoCallEnable, setVideoCallEnable] = useState(false);
  const [audioCallEnable, setAudioCallEnable] = useState(false);
  const [openCallConfirm, setOpenCallConfirm] = useState(false);
  const [callType, setCallType] = useState(''); // 'audio' or 'video'
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [typing, setTyping] = useState(false);
  const [page, setPage] = useState(1); // Track page state for pagination
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tabValue, setTabValue] = useState(0);
  const [callData, setCallData] = useState(null);
  const [callState, setCallState] = useState(null);
  const [emailView, setEmailView] = useState(false);
  const [emailData, setEmailData] = useState(null);
  const [emailDataOutbox, setEmailDataOutbox] = useState(null);
  const [contactsData, setContactsData] = useState(null);
  const [miniProgramsView, setMiniProgramsView] = useState(false);
  const [channelsView, setChannelView] = useState(false);
  const [enablePostView, setEnablePostView] = useState(false);
  const [enableWallet, setEnableWallet] = useState(false);
  const [showLeft, setShowLeft] = useState(true);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [enableSideDrawer, setEnableSideDrawer] = useState(false);
  const [modalFullScreen, setModalFullScreen] = useState(false);
  const [incomingCallModal, setIncomingCallModal] = useState(false);
  const [incomingCallMessage, setIncomingCallMessage] = useState(null);
  const [enableConfirmation, setEnableConfirmation] = useState(false);
  const [registerState, setRegisterState] = useState(2);
  const [onlineStatus, setOnlineStatus] = useState(false);
  const webRtcPeerRef = useRef(null);
  const videoInputRef = useRef(null);
  const videoOutputRef = useRef(null);

  // Fetch chat data and listen for updates via WebSocket

  // Fetch chat list
  const fetchChatDataList = (username) => {
    axios
      .post('https://soapboxapi.megahoot.net/upload/getChatDataPrivateinbox', {
        to: username,
      })
      .then((res) => {
        const payload = res.data.sort(
          (b, a) =>
            new Date(a.chat.timestamp).getTime() -
            new Date(b.chat.timestamp).getTime()
        );
        dispatch(setChatDataList(payload));
      });
  };

  const fetchContacts = (username) => {
    axios
      .get(`https://soapboxapi.megahoot.net/user/follows/${username}`)
      .then((res) => {
        setContactsData(res.data);
      });
  };

  const handleCreatePost = () => {
    console.log('clicked');
  };

  // function connectSocketWithQuery(callerId) {
  //   // if (callerId) {
  //   //   socketCall.io.opts.query = { callerId }; // Set the query parameters
  //   //   socketCall.connect(); // Manually connect the socket
  //   // }
  // }

  const handleHeaderTabClick = (tab) => {
    if (tab === 'EMAIL') {
      setEmailView(true);
    } else if (tab === 'MINIPROGRAMS') {
      setMiniProgramsView(true);
    } else if (tab === 'CHANNELS') {
      setChannelView(true);
    } else if (tab === 'CREATEPOST') {
      setEnablePostView(true);
    } else if (tab === 'WALLET') {
      setEnableWallet(true);
    }
  };

  const fetchEmailData = (username) => {
    axios
      .get(`https://soapboxapi.megahoot.net/emails/inbox/${username}`)
      .then((res) => {
        setEmailData(res.data.reverse());
      });
  };
  const fetchEmailDataOutBox = (username) => {
    axios
      .get(`https://soapboxapi.megahoot.net/emails/outbox/${username}`)
      .then((res) => {
        setEmailDataOutbox(res.data.reverse());
      });
  };

  const inactiveContactsHandler = (payload) => {
    const { username } = payload;
    setContactsData((prevContacts) =>
      prevContacts.map((contact) =>
        contact.username === username ? { ...contact, status: false } : contact
      )
    );
  };
  const activeContactsHandler = (payload) => {
    const { username } = payload;

    setContactsData((prevContacts) =>
      prevContacts.map((contact) =>
        contact.username === username ? { ...contact, status: true } : contact
      )
    );
  };

  //calls starts
  // calls

  const registerResponse = (message) => {
    if (message.response === 'accepted') {
      setRegisterState(REGISTERED);
      setOnlineStatus(true);

      if (message?.activeContacts?.length) {
        const activeUsernames = new Set(
          message.activeContacts.map((c) => c.username)
        );

        setContactsData((prevContacts) =>
          prevContacts?.map((contact) =>
            activeUsernames.has(contact.username)
              ? { ...contact, status: true }
              : contact
          )
        );
      }
    } else {
      setRegisterState(NOT_REGISTERED);
      console.error(
        message.message || 'Unknown reason for register rejection.'
      );
      toast.error(
        'Call status offline. Please check your internet, close other active sessions, and try again.'
      );
    }
  };

  const callResponse = (message) => {
    if (message.response !== 'accepted') {
      console.info('Call not accepted by peer. Closing call', message);
      const errorMessage = message.message
        ? message.message
        : 'Unknown reason for call rejection.';
      console.error(errorMessage);
      stop(true);

      if (errorMessage && errorMessage.includes('not registered')) {
        toast.error('user is offline,call disconnected!');
      } else {
        toast.error('Call not accepted by peer. Closing call');
      }
    } else {
      setCallState(IN_CALL);
      if (webRtcPeerRef.current) {
        webRtcPeerRef.current.processAnswer(message.sdpAnswer);
      }
    }
  };

  const startCommunication = (message) => {
    setCallState(IN_CALL);
    if (webRtcPeerRef.current) {
      webRtcPeerRef.current.processAnswer(message.sdpAnswer);
    }
  };

  const incomingCall = (message) => {
    // For this example, we auto-accept incoming calls.
    setIncomingCallModal(true);
    setIncomingCallMessage(message);
  };

  const callAccepted = () => {
    setVideoCallEnable(true);
    setModalFullScreen(false);
    setTimeout(() => {
      const message = incomingCallMessage;
      setCallState(PROCESSING_CALL);
      setCallType(incomingCallMessage.callType);
      showSpinner(incomingCallMessage.callType);
      const constraints = {
        audio: true,
        video:
          incomingCallMessage.callType === 'audio' ? false : videoConstraints,
      };
      const options = {
        localVideo: videoInputRef.current,
        remoteVideo: videoOutputRef.current,
        onicecandidate: onIceCandidate,
        mediaConstraints: constraints,
      };

      webRtcPeerRef.current = kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(
        options,
        (error) => {
          if (error) {
            console.error(error);
            setCallState(NO_CALL);
            return;
          }
          webRtcPeerRef.current.generateOffer((error, offerSdp) => {
            if (error) {
              console.error(error);
              setCallState(NO_CALL);
              return;
            }
            const response = {
              id: 'incomingCallResponse',
              from: message.from,
              callResponse: 'accept',
              sdpOffer: offerSdp,
            };
            sendMessage(response);
          });
        }
      );

      setIncomingCallModal(false);
    }, 2000);
  };

  const call = (callType) => {
    if (selectedChat?.chatFrom.trim() === '') {
      toast.error('You must specify the peer name');
      return;
    }

    setModalFullScreen(false);
    setCallState(PROCESSING_CALL);
    setCallState(CALLING);
    showSpinner(callType);
    setCallType(callType);

    const constraints = {
      audio: true,
      video: callType === 'audio' ? false : videoConstraints,
    };
    const options = {
      localVideo: videoInputRef.current,
      remoteVideo: videoOutputRef.current,
      onicecandidate: onIceCandidate,
      mediaConstraints: constraints,
    };

    webRtcPeerRef.current = kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(
      options,
      (error) => {
        if (error) {
          console.error(error);
          setCallState(NO_CALL);
          return;
        }

        webRtcPeerRef.current.generateOffer((error, offerSdp) => {
          if (error) {
            console.error(error);
            setCallState(NO_CALL);
            return;
          }
          const message = {
            id: 'call',
            from: userInfo?.username,
            to: selectedChat?.chatFrom,
            sdpOffer: offerSdp,
            callType: callType,
            profilePic: `https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`,
            fcmToken: selectedChat?.fcmToken,
          };
          sendMessage(message);
        });
      }
    );
  };

  const rejectCall = () => {
    const response = {
      id: 'incomingCallResponse',
      from: incomingCallMessage.from,
      callResponse: 'reject',
    };
    sendMessage(response);
    setIncomingCallMessage(null);
    setIncomingCallModal(false);
  };

  const sendContactStatusQuery = () => {
    if (!contactsData) return;
    const message = {
      id: 'onlineStatus',
      from: userInfo?.username,
      contacts: contactsData,
    };
    sendMessage(message);
  };

  // const register = () => {
  //   if (userInfo?.username.trim() === '') {
  //     alert('You must insert your user name');
  //     return;
  //   }
  //   setRegisterState(REGISTERING);
  //   const message = {
  //     id: 'register',
  //     name: userInfo?.username,
  //   };
  //   sendMessage(message);
  // };

  const stop = (fromRemote = false) => {
    setCallState(NO_CALL);
    if (webRtcPeerRef.current) {
      webRtcPeerRef.current.dispose();
      webRtcPeerRef.current = null;
      if (!fromRemote) {
        const message = { id: 'stop' };
        sendMessage(message);
      }
    }
    setVideoCallEnable(false);
    setIncomingCallMessage(null);
    setModalFullScreen(false);
    setCallType('');
    hideSpinner();
  };

  const onIceCandidate = (candidate) => {
    const message = {
      id: 'onIceCandidate',
      candidate: candidate,
    };
    sendMessage(message);
  };

  const showSpinner = (callType) => {
    if (videoInputRef.current) {
      videoInputRef.current.poster = '/transparent-1px.png';
      videoInputRef.current.style.background =
        callType === 'audio'
          ? ''
          : `center transparent url("${
              incomingCallMessage?.profilePic ||
              `https://soapboxapi.megahoot.net/profile-pictures/${selectedChat?.profilePic}`
            }") no-repeat`;
    }
    if (videoOutputRef.current) {
      videoOutputRef.current.poster = '/transparent-1px.png';
      videoOutputRef.current.style.background = `center transparent url("${
        incomingCallMessage?.profilePic ||
        `https://soapboxapi.megahoot.net/profile-pictures/${selectedChat?.profilePic}`
      }") no-repeat`;
    }
  };

  const hideSpinner = () => {
    if (videoInputRef.current) {
      videoInputRef.current.src = '';
      videoInputRef.current.poster = './img/webrtc.png';
      videoInputRef.current.style.background = '';
    }
    if (videoOutputRef.current) {
      videoOutputRef.current.src = '';
      videoOutputRef.current.poster = './img/webrtc.png';
      videoOutputRef.current.style.background = '';
    }
  };
  const handleOpenCallConfirm = (type) => {
    setCallType(type);
    setEnableConfirmation(true);
  };

  const handleCallConfirmationAction = () => {
    setOpenCallConfirm(true);
    setVideoCallEnable(true);
    setEnableConfirmation(false);
    setTimeout(() => {
      call(callType);
    }, 1000);
  };
  // calls ends

  useEffect(() => {
    if (userInfo && userInfo.username) {
      // connectSocketWithQuery(userInfo.username);
      fetchEmailData(userInfo.username);
      fetchEmailDataOutBox(userInfo.username);

      const handlePrivateChat = (data) => {
        fetchChatDataList(userInfo?.username);
        if (data.to === userInfo.username) {
          fetchChatDataList(userInfo?.username);
        }
      };

      socket.on('receive-private-chat-soapbox', handlePrivateChat);

      return () => {
        socket.off('receive-private-chat-soapbox', handlePrivateChat);
      };
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.username) {
      fetchChatDataList(userInfo.username);
      fetchContacts(userInfo.username);
    } else {
      router.push('/');
    }
  }, [userInfo]);

  const handleIncomingMessage = (message) => {
    const parsedMessage = JSON.parse(message.data);

    switch (parsedMessage.id) {
      case 'registerResponse':
        registerResponse(parsedMessage);
        break;
      case 'activeContact':
        activeContactsHandler(parsedMessage);
        break;
      case 'inactiveContact':
        inactiveContactsHandler(parsedMessage);
        break;
      case 'callResponse':
        callResponse(parsedMessage);
        break;
      case 'incomingCall':
        incomingCall(parsedMessage);
        break;
      case 'startCommunication':
        startCommunication(parsedMessage);
        break;
      case 'stopCommunication':
        console.info('❌ Communication ended by remote peer');
        stop(true);
        break;
      case 'iceCandidate':
        if (webRtcPeerRef.current) {
          webRtcPeerRef.current.addIceCandidate(parsedMessage.candidate);
        }
        break;
      default:
        console.error('⚠️ Unrecognized message:', parsedMessage);
    }
  };

  useEffect(() => {
    if (userInfo && contactsData) {
      connectWebSocket(userInfo, handleIncomingMessage, contactsData);
    }
  }, [userInfo, contactsData]);

  return (
    <Grid
      sx={{
        height: '100vh',
        width: '100vw',
        position: 'fixed', // Use relative positioning to accommodate header
      }}
    >
      <DashboardHeader
        handleHeaderTabClick={handleHeaderTabClick}
        image={`https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`}
        sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1200 }} // Ensure header is on top
        setEnableSideDrawer={setEnableSideDrawer}
        enableSideDrawer={enableSideDrawer}
        setTabValue={setTabValue}
        setIframeSrc={setIframeSrc}
      />

      <Grid container sx={{ height: 'calc(100% - 64px)' }}>
        {/* Left Side (30%) */}

        <Grid
          item
          xs={12}
          md={5}
          sx={{
            height: '100%',
            position: 'fixed', // Keep it fixed within the container
            left: 0,
            top: '64px', // Position below the headers
            zIndex: 1100, // Ensure it's below the header
          }}
          width={{ xs: '100%', sm: '40%' }}
        >
          <LeftPanel
            selectedChat={selectedChat}
            isMobile={isMobile}
            chatListData={chatListData}
            contactsData={contactsData}
            setSelectedChat={setSelectedChat}
            setPage={setPage}
            setMessages={setMessages}
            tabValue={tabValue}
            setTabValue={setTabValue}
            setShowLeft={setShowLeft}
            handleHeaderTabClick={handleHeaderTabClick}
            fetchContacts={fetchContacts}
            enableSideDrawer={enableSideDrawer}
            setEnableSideDrawer={setEnableSideDrawer}
            onlineStatus={onlineStatus}
          />
        </Grid>

        {/* Right Side (70%) */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            height: '100%',
            marginLeft: showLeft && !isMobile ? '40%' : '1px', // Push the right area next to the left fixed area
            position: 'relative', // Add relative positioning to manage z-index
            zIndex: 1000, // Ensure the right container is behind the modals but still visible
            backgroundColor: '#121820', // Ensure background stays visible
            overflow: 'hidden', // Prevent content from overflowing
          }}
        >
          {tabValue === 0 ? (
            selectedChat ? (
              <ChatRoom
                setShowLeft={setShowLeft}
                showLeft={showLeft}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
                messages={messages}
                setMessages={setMessages}
                setTabValue={setTabValue}
                setIframeSrc={setIframeSrc}
                videoCallEnable={videoCallEnable}
                setVideoCallEnable={setVideoCallEnable}
                webRtcPeerRef={webRtcPeerRef}
                videoInputRef={videoInputRef}
                videoOutputRef={videoOutputRef}
                callState={callState}
                setCallState={setCallData}
                handleOpenCallConfirm={handleOpenCallConfirm}
              />
            ) : (
              <Feeds setShowLeft={setShowLeft} />
            )
          ) : tabValue === 1 ? (
            <DiscoverScreen
              selectedChat={selectedChat}
              isMobile={isMobile}
              chatListData={chatListData}
              contactsData={contactsData}
              setSelectedChat={setSelectedChat}
              setPage={setPage}
              setMessages={setMessages}
              tabValue={tabValue}
              setTabValue={setTabValue}
              showLeft={showLeft}
              setShowLeft={setShowLeft}
            />
          ) : tabValue === 2 ? (
            <Feeds setShowLeft={setShowLeft} />
          ) : tabValue === 3 ? (
            <Feeds setShowLeft={setShowLeft} />
          ) : tabValue === 4 ? (
            ''
          ) : tabValue === 5 ? (
            <IframeLoader iframeSrc={iframeSrc} setTabValue={setTabValue} />
          ) : tabValue === 6 ? (
            <ChannelChat
              setShowLeft={setShowLeft}
              setSelectedChat={setSelectedChat}
              setPage={setPage}
              setMessages={setMessages}
              tabValue={tabValue}
              setTabValue={setTabValue}
            />
          ) : tabValue === 7 ? (
            <MiniProgramsCatalogue
              setTabValue={setTabValue}
              setIframeSrc={setIframeSrc}
              iframeSrc={iframeSrc}
              setShowLeft={setShowLeft}
            />
          ) : (
            ''
          )}
        </Grid>
      </Grid>

      <CreatePostView
        enablePostView={enablePostView}
        setEnablePostView={setEnablePostView}
        handleCreatePost={handleCreatePost}
      />

      {/* ------------------------------------------------------Modal Views----------------------------------------------------------- */}

      <Dialog
        maxWidth="xl"
        open={tabValue === 4}
        fullWidth={true}
        onClose={() => setTabValue(0)}
      >
        <PublicProfilePage username={userInfo?.username} />
      </Dialog>

      <Dialog
        maxWidth={'xl'}
        open={emailView}
        fullWidth={true}
        onClose={() => setEmailView(false)}
      >
        <Email
          open={emailView}
          fullWidth={true}
          emailData={emailData}
          emailDataOutbox={emailDataOutbox}
          setEmailView={setEmailView}
          fetchEmailData={fetchEmailData}
          fetchEmailDataOutBox={fetchEmailDataOutBox}
        />
      </Dialog>

      <Dialog
        maxWidth={'lg'}
        open={miniProgramsView}
        fullWidth={true}
        onClose={() => setMiniProgramsView(false)}
      >
        <MiniProgramsCatalogue
          setTabValue={setTabValue}
          setIframeSrc={setIframeSrc}
          iframeSrc={iframeSrc}
          setShowLeft={setShowLeft}
        />
      </Dialog>

      <Dialog
        maxWidth={'lg'}
        open={channelsView}
        fullWidth={true}
        onClose={() => setChannelView(false)}
      >
        <ChannelChat />
      </Dialog>

      <Dialog
        maxWidth="xl"
        open={enableWallet}
        fullWidth={true}
        onClose={() => setEnableWallet(false)}
      >
        <iframe
          src="https://wallet.pecunovus.net/"
          width="100%"
          height="800px"
          title="Pecunovus Wallet"
        />
      </Dialog>

      {/* Audio & Video Calls notofication for incoming calls */}

      <Dialog
        maxWidth="sm"
        open={enableConfirmation}
        onClose={() => {
          return;
        }}
      >
        <div
          style={{
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '300px',
            height: '130px',
          }}
        >
          <p style={{ width: '100%', marginTop: '-10px' }}>
            Start {callType} call with {selectedChat?.chatFrom} ?
          </p>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setEnableConfirmation(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                handleCallConfirmationAction();
              }}
            >
              Call
            </Button>
          </div>
        </div>
      </Dialog>

      <Draggable>
        <Dialog
          maxWidth="sm"
          open={incomingCallModal}
          onClose={() => {
            return;
          }}
          hideBackdrop
          disableEnforceFocus
          disablePortal
          PaperProps={{
            sx: {
              bottom: 0,
              right: 0,
              position: 'absolute',
              m: 0,
              width: '300px',
              boxShadow: 3,
              pointerEvents: 'auto', // Allow interaction with Dialog only
            },
          }}
          sx={{
            pointerEvents: 'none', // Allow clicking through the rest of the screen
          }}
        >
          <div
            style={{
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '300px',
            }}
          >
            <h4>
              Incoming {incomingCallMessage?.callType} call from{' '}
              {incomingCallMessage?.from}
            </h4>
            <div className="sound-wave">
              <img
                src={incomingCallMessage?.profilePic}
                width={'100px'}
                height="100px"
                style={{ borderRadius: '50%' }}
                alt={incomingCallMessage?.from}
              />
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={callAccepted}
                fullWidth
              >
                Accept
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="error"
                onClick={rejectCall}
              >
                reject
              </Button>
            </div>
          </div>
        </Dialog>
      </Draggable>

      {/* Audio & Video Calls UI for incoming calls */}
      <Draggable
        disabled={modalFullScreen}
        defaultPosition={{ x: 0, y: 0 }}
        position={modalFullScreen ? { x: 0, y: 0 } : null}
      >
        <Dialog
          fullScreen={modalFullScreen}
          open={videoCallEnable}
          onClose={() => {
            if (callState === IN_CALL || callState === PROCESSING_CALL) {
              return;
            }
            stop();
          }}
          fullWidth={true}
          maxWidth={callType === 'audio' ? 'xs' : 'sm'}
          hideBackdrop
          disableEnforceFocus
          disablePortal
          PaperProps={{
            sx: {
              bottom: 10,
              right: 0,
              position: 'absolute',
              m: 0,
              boxShadow: 3,
              pointerEvents: 'auto', // Allow interaction with Dialog only
              border: '5px solid #0c1016',
              borderRadius: '20px',
            },
          }}
          sx={{
            pointerEvents: 'none', // Allow clicking through the rest of the screen
            minWidth: '300px',
          }}
        >
          {videoCallEnable && (
            <VideoCallPlugin
              modalFullScreen={modalFullScreen}
              setModalFullScreen={setModalFullScreen}
              videoCallEnable={videoCallEnable}
              name={userInfo?.username}
              peer={incomingCallMessage?.from}
              videoInputRef={videoInputRef}
              videoOutputRef={videoOutputRef}
              incomingCallModal={incomingCallModal}
              incomingCallMessage={incomingCallMessage}
              callState={callState}
              registerState={registerState}
              rejectCall={rejectCall}
              stop={stop}
              callAccepted={callAccepted}
              callType={callType}
              selectedChat={selectedChat}
            />
          )}
        </Dialog>
      </Draggable>

      {/* audo notification for incoming call */}
      <IncomingCallHandler incomingCall={incomingCallModal} />
    </Grid>
  );
}

export default Dashboard;
