let socket = null;

export const connectWebSocket = (userInfo, onMessage, contactsData) => {
  if (socket && socket.readyState === WebSocket.OPEN) {
    return socket;
  }

  if (!userInfo || !userInfo.username) {
    return;
  }

  socket = new WebSocket('https://testrandom.megahoot.net/one2one');

  socket.onopen = () => {
    socket.send(
      JSON.stringify({
        id: 'register',
        name: userInfo.username,
        contacts: contactsData,
      })
    );
  };

  socket.onclose = (event) => {
    setTimeout(() => connectWebSocket(userInfo, onMessage), 5000); // Auto-reconnect
  };

  socket.onerror = (error) => {
    console.error('🚨 WebSocket Error:', error);
  };

  socket.onmessage = (message) => {
    if (message.data === 'pong') {
      return;
    }

    if (onMessage) onMessage(message); // Pass message to the handler in the component
  };

  return socket;
};

export const sendMessage = (message) => {
  if (socket && socket.readyState === WebSocket.OPEN) {
    const jsonMessage = JSON.stringify(message);
    socket.send(jsonMessage);
  } else {
    console.error('❌ WebSocket is not connected');
  }
};

export const getWebSocket = () => socket; // Get current WebSocket instance
