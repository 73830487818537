import React, { useEffect, useState, useRef } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setChatDataList } from '../../../redux/reducers/userSlice';
import { v4 as uuidv4 } from 'uuid';
import socket from '../socketConnector';
import { toast } from 'react-toastify';
import ChatInterfaceContainer from './chatInterfaceContainer';
import { socketEvents } from '../../../utils';
import kurentoUtils from 'kurento-utils';
const NOT_REGISTERED = 0;
const REGISTERING = 1;
const REGISTERED = 2;

const NO_CALL = 0;
const PROCESSING_CALL = 1;
const IN_CALL = 2;

const fetchUserStatus = async (username, userInfo, setFollowing) => {
  try {
    const followersResSender = await axios.get(
      `https://soapboxapi.megahoot.net/user/followers/${username}`
    );
    const followersResReceiver = await axios.get(
      `https://soapboxapi.megahoot.net/user/followers/${userInfo.username}`
    );

    const isFollowingUser =
      followersResSender.data.some((e) => e.followedBy === userInfo.username) &&
      followersResReceiver.data.some((e) => e.followedBy === username);

    setFollowing(isFollowingUser);
  } catch (error) {
    console.error(error);
  }
};

function ChatRoom({
  selectedChat,
  setSelectedChat,
  messages,
  setMessages,
  setShowLeft,
  showLeft,
  videoCallEnable,
  setVideoCallEnable,
  setCallState,
  handleOpenCallConfirm,
}) {
  const dispatch = useDispatch();
  const { userInfo, fcmToken } = useSelector((state) => state.userInfo);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [page] = useState(1); // Track page state for pagination
  const [, setOpenCallConfirm] = useState(false);
  const [callType, setCallType] = useState(''); // 'audio' or 'video'
  const [, setAnchorEl] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  // const [videoCallEnable, setVideoCallEnable] = useState(false);
  const [audioCallEnable, setAudioCallEnable] = useState(false);
  const [isEphemeral] = useState(false);
  const [isFollowing, setFollowing] = useState(true);
  const chatContainerRef = useRef(null); // For detecting scroll to top
  const fileInputRef = useRef(null);
  const [replyEnable, setReplyEnable] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [showEmojiList, setShowEmojiList] = useState(false);
  const [userData, setUserData] = useState(null);
  const [registerState, setRegisterState] = useState(2);

  const [incomingCallModal, setIncomingCallModal] = useState(false);
  const [incomingCallMessage, setIncomingCallMessage] = useState(null);
  const videoConstraints = {
    deviceId: 'user',
    frameRate: {
      min: 1,
      ideal: 15,
      max: 30,
    },
    width: {
      min: 640,
      ideal: 1280,
      max: 1280,
    },
    height: {
      min: 360,
      ideal: 720,
      max: 720,
    },
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Process the selected files (images, videos, audio) as needed

    uploadFileUri(file);
    event.target.value = ''; // Reset file input after selection
  };

  const handleAttachClick = () => {
    fileInputRef.current.click(); // Trigger the file input
  };

  const fetchSelectedChatData = (page) => {
    setLoading(true);
    axios
      .post('https://soapboxapi.megahoot.net/upload/getChatDataPrivate', {
        to: selectedChat.chatFrom,
        from: selectedChat.chatTo,
        defaultRoomName: selectedChat?.chat?.defaultRoomName,
        page: page,
        pageSize: 100,
      })
      .then((res) => {
        const data = res.data.map((e) => ({
          _id: e.threadId,
          text: e.chat.message,
          createdAt: e.chat.timestamp,
          seen: e.seen,
          replyParent: e.chat?.replyParent,
          quickReply: e.chat?.quickReply,
          user: {
            _id: e.chatFrom,
            name: e.chatFrom,
            avatar: `https://soapboxapi.megahoot.net/profile-pictures/${e.chat.profilePic}`,
          },
        }));
        if (page === 1) {
          setMessages(data);
        } else {
          setMessages((prevMessages) => [...data, ...prevMessages]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const handleOpenCallConfirm = (type) => {
  //   setCallType(type);
  //   setOpenCallConfirm(true);
  //   if (type === 'audio') {
  //     setVideoCallEnable(true);

  //     setTimeout(() => {
  //       call(type);
  //     }, 1000);
  //   } else {
  //     setVideoCallEnable(true);

  //     setTimeout(() => {
  //       call(type);
  //     }, 1000);
  //   }
  // };

  const handelDeleteAll = () => {
    const deleteAction = async () => {
      await axios.post('https://soapboxapi.megahoot.net/upload/deleteChatAll', {
        roomname: selectedChat.chatFrom + userInfo.username,
      });

      setMessages([]);
    };

    toast.promise(deleteAction, {
      pending: 'Deleting all messages',
      success: 'All messages deleted ',
      error: 'Please try again',
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSaveEdit = (editedText) => {
    setMessages((prevMessages) =>
      prevMessages.map((m) =>
        m._id === editingMessage._id ? { ...m, text: editedText } : m
      )
    );
    setEditingMessage((prev) => ({ ...prev, text: editedText }));
  };
  const confirmEditedMessage = async () => {
    const handleEditMessage = async () => {
      handleSaveEdit(newMessage);
      await axios.post('https://soapboxapi.megahoot.net/upload/editChat', {
        from: editingMessage.user._id,
        threadId: editingMessage._id,
        newMessage: newMessage,
      });
      setEditingMessage(null);
      setEditEnable(false);
      setNewMessage('');
    };

    toast.promise(handleEditMessage, {
      pending: `Editing message`,
      success: `Message edited successfully`,
      error: 'Please try again',
    });
  };

  const fetchuserData = (username) => {
    axios
      .get(`https://soapboxapi.megahoot.net/user/${username}`)
      .then((res) => {
        setUserData(res.data[0]);
      })
      .catch((err) => console.log(err));
  };

  const handleReply = () => {
    if (!newMessage) {
      return;
    }
    if (
      typeof newMessage === 'undefined' ||
      typeof newMessage === 'object' ||
      typeof newMessage === 'function' ||
      typeof newMessage === 'boolean'
    ) {
      return;
    }
    let id = uuidv4();
    let today = new Date();

    const message = newMessage;

    const payload = {
      threadId: id,
      to: selectedChat.chatFrom,
      from: userInfo.username,
      defaultRoomName: selectedChat?.chat?.defaultRoomName,
      profilePic: userInfo?.profilePic,
      channelLogo: selectedChat?.chat?.channelLogo
        ? selectedChat?.chat?.channelLogo
        : userInfo?.profilePic,
      isClub: 0,
      isPrivate: 1,
      isCommunity: 0,
      name: selectedChat.name,
      message: message,
      replyParent: editingMessage,

      isEmoji: isEmoji(message),
      timestamp: today.toISOString(),
      ephemeral: isEphemeral ? true : false,
      deviceToken: fcmToken ?? 'fcmToken', // fcmToken is of the user to whom message is going to be dleivered
    }; // Assuming the user sends the message

    const newMessagesData = [
      {
        _id: id,
        createdAt: today.toUTCString(),
        text: newMessage,
        user: {
          _id: userInfo.username,
          avatar: `https://soapboxapi.megahoot.net/profile-pictures/${userInfo.profilePic}`,
          name: userInfo.name,
        },

        sent: true,
        received: false,
        ephemeral: isEphemeral,
        replyParent: editingMessage,
      },
    ];
    socket.emit(socketEvents.PRIVATE_MESSAGE_SOAPBOX, payload);
    setMessages((prev) => {
      // Check if newMessagesData is defined and has at least one message
      if (newMessagesData && newMessagesData.length > 0) {
        return [newMessagesData[0], ...prev];
      }
      return prev; // Return previous state if no new messages
    });

    setNewMessage('');
    setReplyEnable(false);
    setEditingMessage(null);
    fetchChatDataList(userInfo?.username);
  };

  const handleQuicReplySocket = (data) => {
    socket.emit(socketEvents.PRIVATE_MESSAGE_SOAPBOX_REPLY, {
      messagePayload: data,
      to: selectedChat.chatFrom,
      from: userInfo.username,
    });
  };

  const handleQuicReply = (editedText, editingMessage) => {
    axios
      .post('https://soapboxapi.megahoot.net/upload/editChat', {
        from: editingMessage.user._id,
        threadId: editingMessage._id,
        quickReply: editedText,
        newMessage: editingMessage.text,
      })
      .then((res) => {
        if (res.data && res.data.message) {
          const socketPayload = { ...editingMessage, quickReply: editedText };

          handleQuicReplySocket(socketPayload);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setEditingMessage(null));
    setMessages((prevMessages) =>
      prevMessages.map((m) =>
        m._id === editingMessage._id
          ? {
              ...m,
              quickReply: editedText,
            }
          : m
      )
    );
    setEditingMessage(null);
  };

  const handleMessageRequest = () => {
    const messageRequest = async () => {
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve('success');
      //   }, 1000); // 1 seconds
      // });

      await axios.post(
        `https://soapboxapi.megahoot.net/upload/messageRequest`,
        {
          to: selectedChat.chatFrom,
          from: userInfo?.username,
          status: 'pending',
        }
      );
    };

    toast.promise(messageRequest, {
      pending: `Sending message request to ${selectedChat.chatFrom}`,
      success: `Message request sent to ${selectedChat.chatFrom} successfully`,
      error: 'Please try again',
    });
  };

  function isEmoji(str) {
    var ranges = [
      '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])', // U+1F680 to U+1F6FF
    ];

    if (str?.match(ranges.join('|'))) {
      return true;
    } else {
      return false;
    }
  }

  const uploadFileUri = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const uploadDataToast = async () => {
        const uploadResponse = await axios.post(
          'https://soapboxapi.megahoot.net/upload/uploadFile',
          formData
        );

        if (uploadResponse) {
          const imageUrl = uploadResponse.data;

          handleSocketEmit(
            `https://soapboxapi.megahoot.net/storageChat/${imageUrl.imageUrl}`
          );
        } else {
          console.error('Failed to upload image');
        }
      };

      toast.promise(uploadDataToast, {
        pending: 'Sending Media...',
        success: 'Media Sent Successful',
        error: 'Please try again',
      });
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };
  const handleSocketEmit = (newMessage) => {
    if (replyEnable) {
      handleReply();
    } else if (editEnable) {
      confirmEditedMessage();
    } else {
      let id = uuidv4();
      let today = new Date();

      const message = newMessage;

      const payload = {
        threadId: id,
        to: selectedChat.chatFrom,
        from: userInfo.username,
        defaultRoomName: selectedChat?.chat?.defaultRoomName,
        isClub: 0,
        isPrivate: 1,
        isCommunity: 0,
        name: selectedChat.name,
        message: message,
        replyParent: '',
        profilePic: selectedChat?.chat?.defaultRoomName
          ? selectedChat?.chat?.channelLogo
          : userInfo?.profilePic,
        channelLogo: selectedChat?.chat?.channelLogo
          ? selectedChat?.chat?.channelLogo
          : userInfo?.profilePic,
        isEmoji: isEmoji(message),
        timestamp: today.toISOString(),
        ephemeral: isEphemeral ? true : false,
        deviceToken: selectedChat?.fcmToken ?? 'fcmToken', // fcmToken is of the user to whom message is going to be dleivered
      }; // Assuming the user sends the message

      const newMessagesData = [
        {
          _id: id,
          createdAt: today.toUTCString(),
          text: newMessage,
          user: {
            _id: userInfo.username,
            avatar: `https://soapboxapi.megahoot.net/profile-pictures/${userInfo.profilePic}`,
            name: userInfo.name,
          },

          sent: true,
          received: false,
          ephemeral: isEphemeral,
        },
      ];
      socket.emit(socketEvents.PRIVATE_MESSAGE_SOAPBOX, payload);
      setMessages((prev) => {
        // Check if newMessagesData is defined and has at least one message
        if (newMessagesData && newMessagesData.length > 0) {
          return [newMessagesData[0], ...prev];
        }
        return prev; // Return previous state if no new messages
      });

      setNewMessage('');
      fetchChatDataList(userInfo?.username);
    }
  };

  const handleSocketTyping = () => {
    socket.emit(socketEvents.TYPING, {
      to: selectedChat.chatFrom,
      from: userInfo?.username,
    });
  };

  const fetchChatDataList = (username) => {
    axios
      .post('https://soapboxapi.megahoot.net/upload/getChatDataPrivateinbox', {
        to: username,
      })
      .then((res) => {
        const payload = res.data.sort(
          (b, a) =>
            new Date(a.chat.timestamp).getTime() -
            new Date(b.chat.timestamp).getTime()
        );

        dispatch(setChatDataList(payload));
      });
  };

  const handleDeleteChat = (editingMessage) => {
    const deleteAction = async () => {
      await axios.post('https://soapboxapi.megahoot.net/upload/deleteChat', {
        from: userInfo.username,
        threadId: editingMessage._id,
      });

      setMessages((prevMessages) =>
        prevMessages.filter((m) => m._id !== editingMessage._id)
      );
    };

    toast.promise(deleteAction, {
      pending: 'Deleting Message',
      success: 'Message Deleted ',
      error: 'Please try again',
    });
  };

  const isChatDisabled = () => {
    if (selectedChat?.chat?.defaultRoomName) {
      return false;
    }
    if (isFollowing) {
      return false;
    }

    if (!isFollowing && messages && messages.length === 0) {
      return false;
    }

    return true;
  };

  const handlePrivateChat = (data) => {
    if (
      (data.to === userInfo.username && data.from === selectedChat.chatFrom) ||
      data.to === selectedChat.chat.defaultRoomName
    ) {
      const sentMessage = {
        _id: data.threadId,
        text: data.message,
        ephemeral: data.ephemeral,
        createdAt: data.timestamp,
        replyParent: data?.replyParent,
        isPecuKey: data?.isPecuKey,
        user: {
          _id: data.from,
          name: data.chatFrom,
          avatar: `https://soapboxapi.megahoot.net/profile-pictures/${data.profilePic}`,
        },
      };

      setMessages((prev) => {
        return [sentMessage, ...prev];
      });

      socket.emit(socketEvents.SEEN, {
        to: selectedChat.chatFrom,
        from: userInfo.username,
        ...sentMessage,
        seen: 1,
      });
    }
  };

  const handleSeen = (data) => {
    if (data.to === userInfo.username && data.from === selectedChat.chatFrom) {
      setMessages((prevMessages) =>
        prevMessages.map((e) => {
          if (e._id === data._id) {
            return { ...e, seen: 1 }; // Update the message with seen status
          }
          return e; // Return other messages unchanged
        })
      );
    }
  };

  const handleTyping = ({ to, from }) => {
    if (
      (to === userInfo.username && from === selectedChat.chatFrom) ||
      to === selectedChat.chat.defaultRoomName
    ) {
      setIsTyping(true);
      const typingTimeout = setTimeout(() => {
        setIsTyping(false);
      }, 10000);

      // Clear the timeout when the 'typing' event is received again
      const clearTypingTimeout = () => {
        clearTimeout(typingTimeout);
        setIsTyping(false);
      };

      socket.once(socketEvents.TYPING, ({ to: nextTo, from: nextFrom }) => {
        if (
          nextTo !== userInfo.username ||
          nextFrom !== selectedChat.chatFrom
        ) {
          clearTypingTimeout();
        }
      });
    }
  };

  useEffect(() => {
    if (userInfo && selectedChat) {
      fetchuserData(selectedChat?.chatFrom);
      fetchUserStatus(selectedChat?.chatFrom, userInfo, setFollowing);
      // Register socket event listeners
      socket.on(socketEvents.RECEIVE_PRIVATE_CHAT_SOAPBOX, handlePrivateChat);
      socket.on(socketEvents.SEEN, handleSeen);
      socket.on(socketEvents.TYPING, handleTyping);

      // Cleanup function to remove event listeners
      return () => {
        socket.off(
          socketEvents.RECEIVE_PRIVATE_CHAT_SOAPBOX,
          handlePrivateChat
        );
        socket.off(socketEvents.SEEN, handleSeen);
        socket.off(socketEvents.TYPING, handleTyping);
      };
    }
  }, [userInfo, selectedChat]);

  useEffect(() => {
    if (selectedChat && selectedChat.chatFrom) {
      fetchSelectedChatData(page);
    }
  }, [selectedChat]);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      style={{
        height: '100vh',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <ChatInterfaceContainer
        userData={userData}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        setShowLeft={setShowLeft}
        setSelectedProfile={setSelectedProfile}
        setShowProfile={setShowProfile}
        isFollowing={isFollowing}
        handleMessageRequest={handleMessageRequest}
        handleOpenCallConfirm={handleOpenCallConfirm}
        handelDeleteAll={handelDeleteAll}
        chatContainerRef={chatContainerRef}
        isTyping={isTyping}
        handleClick={handleClick}
        messages={messages}
        userInfo={userInfo}
        handleDeleteChat={handleDeleteChat}
        setEditingMessage={setEditingMessage}
        editingMessage={editingMessage}
        replyEnable={replyEnable}
        setReplyEnable={setReplyEnable}
        setEditEnable={setEditEnable}
        setNewMessage={setNewMessage}
        handleQuicReply={handleQuicReply}
        editEnable={editEnable}
        loading={loading}
        handleSocketTyping={handleSocketTyping}
        newMessage={newMessage}
        handleSocketEmit={handleSocketEmit}
        isChatDisabled={isChatDisabled}
        setShowEmojiList={setShowEmojiList}
        handleAttachClick={handleAttachClick}
        fileInputRef={fileInputRef}
        handleFileChange={handleFileChange}
        showLeft={showLeft}
        showEmojiList={showEmojiList}
        videoCallEnable={videoCallEnable}
        setVideoCallEnable={setVideoCallEnable}
        setCallType={setCallType}
        setOpenCallConfirm={setOpenCallConfirm}
        setCallState={setCallState}
        audioCallEnable={audioCallEnable}
        setAudioCallEnable={setAudioCallEnable}
        showProfile={showProfile}
        selectedProfile={selectedProfile}
      />
    </Grid>
  );
}

export default ChatRoom;
