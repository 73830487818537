import {
  Avatar,
  Card,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getSecondaryText } from '../../../utils';
import { useTheme } from '@emotion/react';
import { format, isToday, isThisWeek } from 'date-fns';
import { FiberManualRecord, OnlinePrediction } from '@mui/icons-material';
function ChatList({
  chatListData,
  setSelectedChat,
  selectedChat,
  setMessages,
  setPage,
  setTabValue,
  setShowLeft,
  value,
  contactsData,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeUsers, setActiveUsers] = useState([]);
  const formatDate = (date) => {
    const messageDate = new Date(date);
    if (isToday(messageDate)) {
      return format(messageDate, 'p'); // e.g., "12:34 PM"
    } else if (isThisWeek(messageDate)) {
      return format(messageDate, 'EEEE'); // e.g., "Monday"
    } else {
      return format(messageDate, 'MMM dd'); // e.g., "Jun 22"
    }
  };

  const getStatus = (chatData) => {
    const existingUser = contactsData?.filter(
      (e) =>
        e.username === (chatData.chatFrom || chatData.name || chatData.username)
    );

    if (existingUser && existingUser.length > 0) {
      return existingUser.status;
    }
    return false;
  };

  useEffect(() => {
    const activeLocal = contactsData?.filter((e) => e.status);
    setActiveUsers(activeLocal?.map((e) => e.username));
  }, [contactsData]);

  return (
    value === 0 && (
      <List
        sx={{
          maxHeight: '500px',
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingBottom: '10px',
          width: '100%',
        }}
      >
        {chatListData?.map((chatRoom) => (
          <Card
            button
            key={chatRoom.id}
            onClick={() => {
              setSelectedChat(chatRoom);
              setMessages(null);
              setPage(1);
              setTabValue(0);
              if (isMobile) {
                setShowLeft(false);
              }
            }}
            selected={selectedChat?.id === chatRoom.id}
            sx={{
              borderRadius: 1,
              marginBottom: 1,
              padding: '10px 15px',
              cursor: 'pointer',
              bgcolor: '#171d26',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
            }}
          >
            <Avatar
              style={{
                backgroundColor: theme.palette.primary.main,
              }}
              src={
                chatRoom?.chat?.defaultRoomName
                  ? `https://soapboxapi.megahoot.net/profile-pictures/${chatRoom?.chat?.channelLogo}`
                  : `https://soapboxapi.megahoot.net/profile-pictures/${chatRoom?.profilePic}`
              }
            >
              {chatRoom?.chat?.defaultRoomName?.slice(0, 1) ||
                chatRoom.name?.slice(0, 1) ||
                chatRoom.username?.slice(0, 1) ||
                chatRoom.chatFrom?.slice(0, 1)}
            </Avatar>
            {!chatRoom?.chat?.defaultRoomName && (
              <span
                style={{
                  color: activeUsers?.includes(chatRoom?.chatFrom)
                    ? 'green'
                    : 'red',
                  marginRight: 12,
                }}
              >
                <FiberManualRecord
                  color="inherit"
                  sx={{
                    fontSize: '18px',
                    position: 'absolute',
                    left: '40px',
                  }}
                />
              </span>
            )}
            <ListItemText
              color="#fff"
              primary={
                (chatRoom?.chat?.defaultRoomName &&
                  chatRoom?.chat?.defaultRoomName + ' Channel') ||
                chatRoom.name ||
                chatRoom.username ||
                chatRoom.chatFrom
              }
              secondary={getSecondaryText(chatRoom)}
              primaryTypographyProps={{ fontWeight: 500, color: '#fff' }}
              secondaryTypographyProps={{ fontWeight: 500, color: '#a3a8aa' }}
            />
            <Typography>
              <Typography
                variant="body2"
                style={{ fontSize: '12px', color: '#fff' }}
              >
                {formatDate(chatRoom.createdAt)}
              </Typography>
            </Typography>
          </Card>
        ))}
      </List>
    )
  );
}

export default ChatList;
