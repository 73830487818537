import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  Avatar,
  Button,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RepeatIcon from '@mui/icons-material/Repeat';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@emotion/react';
import { MoreVert, ShareOutlined, Visibility } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import FeedContent from './feedContent';
import FeedContentEmbed from './feedContentEmbed';

function base64Decode(encodedData) {
  const binaryString = atob(encodedData);
  const uint8Array = new Uint8Array(
    [...binaryString].map((char) => char.charCodeAt(0))
  );
  const decoder = new TextDecoder();
  return decoder.decode(uint8Array);
}
const FeedPageEmbed = () => {
  const { userInfo } = useSelector((state) => state.userInfo);
  const [feedData, setFeedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [playingVideos, setPlayingVideos] = useState({});
  const videoRefs = useRef([]);
  const observerRefs = useRef([]);
  const theme = useTheme();

  let { hootId } = useParams();
  hootId = base64Decode(hootId);
  const [hoot, setHoot] = useState(null);

  const BaseURL = process.env.REACT_APP_API_URL;

  const handleLike = async (hootId) => {
    try {
      await axios.put('https://soapboxapi.megahoot.net/upload/increment-like', {
        hootId,
      });
      setFeedData((prevData) =>
        prevData.map((post) =>
          post.hootId === hootId ? { ...post, likes: post.likes + 1 } : post
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleRepost = async (postId) => {
    if (window.confirm('Are you sure you want to repost this post?')) {
      try {
        await axios.post('https://soapboxapi.megahoot.net/upload/repost', {
          postId,
          username: userInfo.username,
          email: userInfo.email,
        });
        alert('Reposted from your timeline');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const togglePlayPause = (index) => {
    const video = videoRefs.current[index];
    if (playingVideos[index]) {
      video.pause();
    } else {
      video.play();
    }
    setPlayingVideos((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    const getHootById = async () => {
      await axios.get(`${BaseURL}/hoot/public/${hootId}`).then((response) => {
        setHoot(response.data[0]);
      });
    };

    try {
      getHootById();
    } catch (error) {
      console.log('error fetching...', error);
    }
  }, []);

  return (
    <Grid item xs={12} sm={12} sx={{ backgroundColor: '#000', height: '100%' }}>
      {!hoot ? (
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            maxWidth: '400px',
            margin: '0 auto',
            borderRadius: '20px',
            position: 'relative',
            backgroundColor: '#000',
            padding: '1px',
            height: '80vh',
            objectFit: 'cover',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Card>
      ) : (
        <Card
          id={`feed-item-0`}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            maxWidth: '400px',
            margin: '0 auto',
            borderRadius: '20px',
            position: 'relative',
            backgroundColor: '#000',
            padding: '1px',
          }}
        >
          {hoot.mimeType.includes('video') ||
          hoot.mimeType.includes('audio') ? (
            <>
              <CardMedia
                component="video"
                controlsList="nodownload" // Remove download option
                loading="lazy"
                src={`https://soapboxapi.megahoot.net/images/${hoot.image}`}
                poster={hoot?.mimeType?.includes('audio') && `/audio.png`}
                ref={(el) => (videoRefs.current[0] = el)}
                controls={true}
                sx={{ width: 'auto', height: '70vh', objectFit: 'cover' }}
                muted={true}
                onClick={() => togglePlayPause(0)}
                autoPlay={true}
                loop={true}
              />
              {/* <IconButton
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: theme.palette.primary.main,
                    zIndex: 2,
                  }}
                  onClick={() => togglePlayPause(index)}
                >
                  {playingVideos[index] ? (
                    <PauseIcon sx={{ fontSize: 40, color: '#fff' }} />
                  ) : (
                    <PlayArrowIcon sx={{ fontSize: 40, color: '#fff' }} />
                  )}
                </IconButton> */}
            </>
          ) : (
            <CardMedia
              component="img"
              loading="lazy"
              image={`https://soapboxapi.megahoot.net/images/${hoot.image}`}
              alt="feed"
              sx={{ width: '100%', height: '70vh', objectFit: 'cover' }}
            />
          )}
          <FeedContentEmbed hoot={hoot} setFeedData={setFeedData} />
        </Card>
      )}
    </Grid>
  );
};

export default FeedPageEmbed;
