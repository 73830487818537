import { useTheme } from '@emotion/react';
import {
  Add,
  AddCircle,
  AppsOutlined,
  EmailOutlined,
  ExploreOutlined,
  GroupOutlined,
  HomeOutlined,
  Notifications,
  OndemandVideoOutlined,
  OnlinePrediction,
  SearchOutlined,
  SpeakerGroupOutlined,
  WalletOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Badge,
  BottomNavigationAction,
  Box,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function TabList({
  setTabValue,
  setShowLeft,
  setSelectedChat,
  tabValue,
  handleHeaderTabClick,
  requestCount,
  setEnableRequest,
  onlineStatus,
}) {
  const { userInfo } = useSelector((state) => state.userInfo);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    !isMobile && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'column', // Align navigation items vertically
          alignItems: 'center',
          justifyContent: 'space-between',
          height: isMobile ? '50px' : '100%',
          backgroundColor: '#171d26',
          color: '#fff',
          paddingY: '15px',
          overflow: 'auto',
          minWidth: '120px',
        }}
      >
        <Button
          onClick={() => {
            setTabValue(4);
            if (isMobile) setShowLeft(false);
          }}
          sx={{
            color: tabValue === 4 ? '#fff' : '#c2c2c2', // Text and icon color
            display: 'flex',
            flexDirection: 'column',
            bgcolor: tabValue === 4 ? '#3d215b' : '#171d26',
            width: '100%',
            textTransform: 'none',
            marginBottom: '20px',
          }}
          variant={tabValue === 4 ? 'contained' : ''}
          size="small"
        >
          <Avatar
            src={`https://soapboxapi.megahoot.net/profile-pictures/${userInfo?.profilePic}`}
            style={{
              backgroundColor: theme.palette.primary.light,
              width: '40px',
              height: '40px',
            }}
          />

          <Typography
            color="#fff"
            sx={{
              fontSize: '10px',
            }}
          >
            {userInfo?.name}{' '}
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            @{userInfo?.username}
            <span style={{ color: onlineStatus ? 'green' : 'red' }}>
              <OnlinePrediction color="inherit" sx={{ marginLeft: '3px' }} />
            </span>
          </Typography>
        </Button>
        {[
          {
            label: 'Dashboard',
            icon: <HomeOutlined />,
            onClick: () => {
              setTabValue(0);
              if (isMobile) setShowLeft(false);
              setSelectedChat(null);
            },
            selected: tabValue === 0,
          },

          {
            label: 'Discover',
            icon: <ExploreOutlined />,
            onClick: () => {
              setTabValue(1);
              if (isMobile) setShowLeft(false);
            },
            selected: tabValue === 1,
          },
          {
            label: 'Post',
            icon: <Add sx={{ fontSize: 40 }} />,
            onClick: () => handleHeaderTabClick('CREATEPOST'),
            selected: tabValue === 2,
          },
          {
            label: 'Soapbox',
            icon: <OndemandVideoOutlined />,
            onClick: () => {
              setTabValue(3);
              if (isMobile) setShowLeft(false);
            },
            selected: tabValue === 3,
          },
          {
            label: 'Mega Mail',
            icon: <EmailOutlined />,
            onClick: () => handleHeaderTabClick('EMAIL'),
          },
          // {
          //   label: 'Wallet',
          //   icon: <WalletOutlined />,
          //   onClick: () => handleHeaderTabClick('WALLET'),
          // },
          {
            label: 'Channels',
            icon: <GroupOutlined />,
            onClick: () => setTabValue(6),
            selected: tabValue === 6,
          },
          {
            label: 'Clubs',
            icon: <SpeakerGroupOutlined />,
            onClick: () => handleHeaderTabClick('CHANNELS'),
          },
          {
            label: 'Mini Apps',
            icon: <AppsOutlined />,
            onClick: () => {
              setTabValue(7);
              if (isMobile) setShowLeft(false);
            },
            selected: tabValue === 7,
          },
          // {
          //   label: `Notifications`,
          //   icon: (
          //     <Badge badgeContent={requestCount} color="error">
          //       <Notifications />
          //     </Badge>
          //   ),
          //   onClick: () => {
          //     handleHeaderTabClick('NOTIFICATIONS');
          //     setEnableRequest(true);
          //   },
          // },
        ].map((item, index) => (
          <Button
            key={index}
            onClick={item.onClick}
            sx={{
              color: item.selected ? '#fff' : '#c2c2c2', // Text and icon color
              display: 'flex',
              flexDirection: 'column',
              bgcolor: item.selected ? '#3d215b' : '#171d26',
              width: '90%',
              textTransform: 'none',
              paddingY: item.selected ? '10px' : '',
              fontWeight: 'bold',
              fontSize: '9px',
            }}
            variant={item.selected ? 'contained' : ''}
            size="small"
          >
            {item.icon}
            {item.label}
          </Button>
        ))}
      </Box>
    )
  );
}

export default TabList;
