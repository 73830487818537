import { useTheme } from '@emotion/react';
import {
  Avatar,
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getSecondaryText } from '../../../utils';
import { OnlinePrediction } from '@mui/icons-material';

function ContactList({
  contactsData,
  setSelectedChat,
  setMessages,
  setPage,
  setTabValue,
  setShowLeft,
  value,
}) {
  const { userInfo } = useSelector((state) => state.userInfo);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    value === 1 && (
      <List sx={{ maxHeight: '500px', overflow: 'auto', width: '100%' }}>
        {contactsData?.map((chatRoom, key) => (
          <Card
            button
            key={key}
            // selected={selectedChat?.id === chatRoom.id}
            sx={{
              borderRadius: 1,
              marginBottom: 1,
              padding: '10px 15px',
              cursor: 'pointer',
              bgcolor: '#171d26',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Avatar
              style={{
                backgroundColor: theme.palette.primary.main,
                marginRight: 12,
              }}
              src={`https://soapboxapi.megahoot.net/profile-pictures/${chatRoom?.profilePic}`}
            >
              {chatRoom.name?.slice(0, 1) ||
                chatRoom.username?.slice(0, 1) ||
                chatRoom.chatFrom?.slice(0, 1)}
            </Avatar>
            <span style={{ color: chatRoom?.status ? 'green' : 'red' }}>
              <OnlinePrediction color="inherit" sx={{ marginLeft: '3px' }} />
            </span>
            <ListItemText
              primary={chatRoom.name || chatRoom.username || chatRoom.chatFrom}
              secondary={`@${chatRoom?.username?.toLowerCase()}`}
              primaryTypographyProps={{ fontWeight: 500, color: '#fff' }}
              secondaryTypographyProps={{ color: '#fff' }}
            />
            <Button
              onClick={() => {
                setSelectedChat({
                  chatFrom: chatRoom.username,
                  chatTo: userInfo.username,
                  createdAt: '',
                  fcmToken: '',
                  name: chatRoom.name,
                  profilePic: chatRoom.profilePic,
                  roomname: userInfo.username + chatRoom.username,
                  seen: 0,
                  shared_public_key: '',
                  threadId: '',
                  chat: null,
                });
                setMessages(null);
                setPage(1);
                setTabValue(0);
                if (isMobile) {
                  setShowLeft(false);
                }
              }}
              variant="contained"
            >
              Message
            </Button>
          </Card>
        ))}
      </List>
    )
  );
}

export default ContactList;
