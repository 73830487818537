import React, { useEffect, useState } from 'react';
import { Box, IconButton, Paper } from '@mui/material';
import {
  CallEnd,
  MicOff,
  Mic,
  Videocam,
  VideocamOff,
  SwapHoriz,
  ScreenShare,
  GridView,
  ViewSidebar,
  ViewSidebarOutlined,
  Fullscreen,
  FullscreenExit,
} from '@mui/icons-material';

import './sampleCall.css';
import AudioVisualizer from './AudioVisualizer';
// Constants for register and call states
const NOT_REGISTERED = 0;
const REGISTERING = 1;
const REGISTERED = 2;
const CALLING = 3;
const NO_CALL = 0;
const PROCESSING_CALL = 1;
const IN_CALL = 2;

const VideoCallPlugin = ({
  videoInputRef,
  videoOutputRef,
  callState,
  stop,
  callType,
  incomingCallMessage,
  peer,
  setModalFullScreen,
  modalFullScreen,
  selectedChat,
}) => {
  const [audioTrackEnabled, setAudioTraceEnabled] = useState(true);
  const [videoTrackEnabled, setVideoTraceEnabled] = useState(true);
  const [fullScreen, setFullScreen] = useState('remote');
  const [isGridView, setIsGridView] = useState(false);
  const [audioStream, setAudioStream] = useState(null);

  const toggleCamera = () => {
    if (videoInputRef.current) {
      const mediaStream = videoInputRef?.current.srcObject;
      const videoTracks = mediaStream?.getVideoTracks();
      videoTracks.forEach((track) => (track.enabled = !track.enabled));
      setVideoTraceEnabled((prev) => !prev);
    } else {
      console.warn('No audio track found.');
    }
  };
  const toggleMic = () => {
    if (videoInputRef.current) {
      const mediaStream = videoInputRef?.current.srcObject;
      const audioTracks = mediaStream?.getAudioTracks();
      audioTracks.forEach((track) => (track.enabled = !track.enabled));
      setAudioTraceEnabled((prev) => !prev);
    } else {
      console.warn('No audio track found.');
    }
  };

  const getVerb = () => {
    if (callState === IN_CALL) {
      return 'Talking';
    }
    if (callState === PROCESSING_CALL) {
      return 'Connecting';
    }

    return 'Connecting';
  };
  const getClassName = (videoType) => {
    if (videoType === 'local') {
      return fullScreen === 'local' ? 'remote-video' : 'local-video';
    }
    if (videoType === 'remote') {
      return fullScreen === 'remote' ? 'remote-video' : 'local-video';
    }
  };
  const swapView = () => {
    if (callType === 'audio') return;
    setFullScreen((prev) => (prev === 'remote' ? 'local' : 'remote'));
  };

  useEffect(() => {
    if (!modalFullScreen) {
      setIsGridView(false);
    }
  }, [modalFullScreen]);
  useEffect(() => {
    // Check for changes in videoOutputRef.current and its srcObject
    const checkAudioStream = () => {
      if (videoOutputRef.current?.srcObject) {
        setAudioStream(videoOutputRef.current.srcObject);
      }
    };

    checkAudioStream(); // Run it initially

    // Add an interval to keep checking for a few seconds (optional)
    const interval = setInterval(checkAudioStream, 500);

    return () => clearInterval(interval);
  }, [videoOutputRef.current]); // Dependency on videoOutputRef.current
  return (
    <div
      className="call-container"
      style={{
        minHeight: callType === 'audio' || !modalFullScreen ? '200px' : '93vh',
        backgroundColor: isGridView ? '#000' : '#fff',
      }}
    >
      <div className="call-header">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <img src="/chathive-w.png" width="80px" alt="mChatHive" />{' '}
          <span>
            {getVerb()} to {incomingCallMessage?.from || selectedChat?.chatFrom}
          </span>
        </div>

        <Box className="call-controls">
          {/* Toggle Camera */}
          {callType === 'video' && (
            <IconButton
              onClick={toggleCamera}
              color={videoTrackEnabled ? 'inherit' : 'error'}
              disabled={callState !== IN_CALL}
            >
              {videoTrackEnabled ? <Videocam /> : <VideocamOff />}
            </IconButton>
          )}

          {/* Toggle Mic */}
          <IconButton
            onClick={toggleMic}
            color={audioTrackEnabled ? 'inherit' : 'error'}
            disabled={callState !== IN_CALL}
          >
            {audioTrackEnabled ? (
              <Mic color="inherit" />
            ) : (
              <MicOff color="error" />
            )}
          </IconButton>

          {/* Swap View */}
          {callType === 'video' && (
            <IconButton onClick={swapView} color="inherit" disabled={true}>
              <ScreenShare />
            </IconButton>
          )}
          {callType === 'video' && (
            <IconButton
              onClick={swapView}
              color="inherit"
              disabled={callType === 'audio' || callState !== IN_CALL}
            >
              <SwapHoriz />
            </IconButton>
          )}
          {callType === 'video' && modalFullScreen && (
            <IconButton
              onClick={() => setIsGridView((prev) => !prev)}
              color="inherit"
              disabled={callType === 'audio' || callState !== IN_CALL}
            >
              {isGridView ? <GridView /> : <ViewSidebarOutlined />}
            </IconButton>
          )}
          {callType === 'video' && (
            <IconButton
              onClick={() => setModalFullScreen((prev) => !prev)}
              color="inherit"
              disabled={callType === 'audio' || callState !== IN_CALL}
            >
              {modalFullScreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          )}

          {/* End Call */}
          <IconButton
            onClick={() => stop(false)}
            color="error"
            // disabled={callState !== IN_CALL}
          >
            <CallEnd />
          </IconButton>
        </Box>
      </div>
      <div
        className="video-container"
        // style={{ background: `url(${incomingCallMessage?.profilePic})` }}
      >
        <video
          className={isGridView ? 'grid-video' : getClassName('local')}
          onClick={swapView}
          ref={videoInputRef}
          autoPlay
          muted
        />
        <video
          className={isGridView ? 'grid-video' : getClassName('remote')}
          onClick={swapView}
          ref={videoOutputRef}
          autoPlay
        />
        {/* <img
          src={incomingCallMessage?.profilePic}
          style={{ width: '100px', height: '100px', borderRadius: '50px' }}
          alt={incomingCallMessage?.from || selectedChat?.chatFrom}
        /> */}
        {callType === 'audio' && callState === IN_CALL && audioStream && (
          <AudioVisualizer audioStream={audioStream} />
        )}
      </div>
    </div>
  );
};

export default VideoCallPlugin;
