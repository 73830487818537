import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './screens/login';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import SignUp from './screens/signup';
import Dashboard from './screens/dashboard/dashboard';
import store from './redux/store';
import { Provider } from 'react-redux';
import LoadingScreen from './screens/loadingScreen';
import PublicProfilePage from './screens/dashboard/publicProfilePage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Zoom } from 'react-toastify';
import FeedPage from './screens/dashboard/feeds/feedPage';
import FeedPageEmbed from './screens/dashboard/feeds/feedPageEmbed';
import SampleCall from './screens/dashboard/test-calls/sampleCall';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#3d215b',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Prevent uppercase transformation globally
          },
        },
      },
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            transition={Zoom}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
            className="react-toastify"
            bodyClassName="toast-body"
          />
          <Routes>
            <Route path="/" element={<LoadingScreen />} />
            <Route path="/samplecall" element={<SampleCall />} />
            <Route path="/feed/:hootId" element={<FeedPage />} />
            <Route path="/embed/feed/:hootId" element={<FeedPageEmbed />} />
            <Route path="/home" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/profile/:username" element={<PublicProfilePage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
