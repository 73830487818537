import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
  Stack,
  Box,
  CircularProgress,
  Dialog,
  Chip,
  Paper,
  Divider,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RepeatIcon from '@mui/icons-material/Repeat';
import { useSelector } from 'react-redux';
import DashboardHeader from './header';
import { useTheme } from '@emotion/react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import {
  Label,
  MoreVert,
  MoreVertOutlined,
  Visibility,
} from '@mui/icons-material';
import { formatCount, formatSi, getVideoPoster } from '../../utils';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import IndividualPostOverlay from './individualPostOverlay';
import ProfileEditScreen from './profileEditComponent';
import UserBadge from './userBadge';
const PublicProfilePage = (prop) => {
  const theme = useTheme();
  const { token, userInfo } = useSelector((state) => state.userInfo);
  const usernameParams = useParams();
  const username = prop?.username || usernameParams?.username;
  const {
    setSelectedChat,
    setPage,
    setMessages,
    setTabValue,
    ChannelRoomName,
    isChannel,
  } = prop;
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [userLinks, setUserLinks] = useState([]);
  const [showMoreLinks, setShowMoreLinks] = useState(false);
  const [following, setFollowing] = useState(false);
  const [playingVideos, setPlayingVideos] = useState({});
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [views, setViews] = useState('');
  const [likes, setLikes] = useState('');
  const [postData, setPostData] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const videoRefs = useRef([]);
  const [enableEditProfile, setEnableEditProfile] = useState(false);
  const [postDataStyles, setPostDataStyles] = useState(null);
  const [channelList, setChannelList] = useState(null);
  const [channelData, setChannelData] = useState(null);
  const fetchUserData = async (username) => {
    try {
      const [followersRes, userRes] = await Promise.all([
        axios.get(`https://soapboxapi.megahoot.net/user/followers/${username}`),
        axios.get(`https://soapboxapi.megahoot.net/user/${username}`),
      ]);

      const isFollowing = followersRes.data.some(
        (e) => e.followedBy === userInfo.username
      );
      setFollowing(isFollowing);

      if (userRes.data.length > 0) {
        const userData = userRes.data[0];
        setUser(userData);

        // Convert user data values to absolute URLs if they are valid
        const links = Object.entries(userData).reduce((acc, [key, value]) => {
          // Check if value is a string and looks like a URL
          if (
            typeof value === 'string' &&
            (value.startsWith('http://') ||
              value.startsWith('https://') ||
              value.startsWith('www.') ||
              value.endsWith('.com')) &&
            key !== 'email'
          ) {
            // Construct a valid URL
            const url = value.startsWith('http') ? value : `https://${value}`;
            acc.push({ label: key, link: url });
          }
          return acc;
        }, []);
        setUserLinks(links);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchChannelListNew = (username) => {
    setChannelList(null);
    axios
      .get(`https://soapboxapi.megahoot.net/channels/owner/${username}`)
      .then((res) => {
        if (res.data && res.data.channels) {
          setChannelList(res.data.channels);
        }
      });
  };
  const fetchPosts = (username) => {
    axios
      .get(
        `https://soapboxapi.megahoot.net/upload/user/public/p/${username}?page=${pageIndex}`
      )
      .then((res) => {
        if (res.data && res.data.results) {
          // Append new posts to existing ones
          setPosts((prevPosts) => [...prevPosts, ...res.data.results]);

          // Set total likes and views (assumes totalLikes and totalViews are constant across pages)
          setLikes(res.data.totalLikes);
          setViews(res.data.totalViews);

          // Check if there are more pages available
          if (res.data.results.length < 10) {
            setHasMore(false); // No more posts if results are fewer than the limit
          }
        }
      })
      .catch((error) => console.error('Error fetching posts:', error));
  };

  const handleFollow = () => {
    if (userInfo) {
      const followUser = async () => {
        await axios.post(`https://soapboxapi.megahoot.net/user/followedBy`, {
          username,
          loggedInUsername: userInfo?.username,
        });
        setFollowing(true);
      };

      toast.promise(followUser, {
        pending: `following ${username}`,
        success: ` ${username} followed successfully`,
        error: 'Please try again',
      });
    }
  };

  const handleUnfollow = async () => {
    try {
      const unfollowUser = async () => {
        await axios.post(
          `https://soapboxapi.megahoot.net/user/followedBy/delete`,
          {
            username,
            loggedInUsername: userInfo?.username,
          }
        );
        setFollowing(false);
      };

      toast.promise(unfollowUser, {
        pending: `Unfollowing ${username}`,
        success: ` ${username} Unfollowed `,
        error: 'Please try again',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleBlockUser = async () => {
    try {
      const blockUser = async () => {
        await axios.post(
          `https://soapboxapi.megahoot.net/user/followedBy/delete`,
          {
            username,
            loggedInUsername: userInfo?.username,
          }
        );
        await axios.post(
          `https://soapboxapi.megahoot.net/user/followedBy/delete`,
          {
            username: userInfo?.username,
            loggedInUsername: username,
          }
        );
        setFollowing(false);
      };

      toast.promise(blockUser, {
        pending: `Blocking ${username}`,
        success: ` ${username} blocked `,
        error: 'Please try again',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMessageRequest = () => {
    const messageRequest = async () => {
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve('success');
      //   }, 1000); // 1 seconds
      // });

      await axios.post(
        `https://soapboxapi.megahoot.net/upload/messageRequest`,
        {
          to: username,
          from: userInfo?.username,
          status: 'pending',
        }
      );
    };

    toast.promise(messageRequest, {
      pending: `Sending request to ${username}`,
      success: `Follow Request sent to ${username} successfully`,
      error: 'Please try again',
    });
  };

  const fetchChannelData = () => {
    axios
      .get(
        `https://soapboxapi.megahoot.net/channels/username/${ChannelRoomName}`
      )
      .then((res) => {
        setChannelData(res.data.channel);
      });
  };

  useEffect(() => {
    if (postData) {
      const fontFamilyStyleRaw =
        postData?.fontFamilyStyle && postData.fontFamilyStyle.includes('{')
          ? JSON.parse(postData.fontFamilyStyle)
          : null;
      setPostDataStyles(fontFamilyStyleRaw);
    }
  }, [postData]);
  useEffect(() => {
    if (username) {
      if (isChannel) {
        fetchChannelData();
      } else {
        fetchUserData(username);
        fetchPosts(username);
      }
    }
  }, [username, pageIndex, userInfo]);

  return (
    <div
      style={{
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <DashboardHeader
        image={null}
        sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1200 }} // Ensure header is on top
      />
      {user && (
        <div style={{ maxWidth: '85vw', marginTop: '100px' }}>
          {/* <h1>{user.name}</h1> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={`https://soapboxapi.megahoot.net/profile-pictures/${user.profilePic}`}
              alt={user.username}
              style={{ width: 150, height: 150, borderRadius: '50%' }}
            />
            <div style={{ marginLeft: 20 }}>
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography variant="h6">
                  {user.name}
                  {user.verified === 1 ? <UserBadge userData={user} /> : null}
                </Typography>
                <Typography variant="subtitle1">@{user.username}</Typography>
              </Stack>

              {following ? (
                <Stack
                  direction="column"
                  gap={1}
                  mt={1}
                  sx={{ position: 'relative' }}
                >
                  <Stack direction="row" gap={2}>
                    <Typography fontWeight="bold">
                      {formatCount(user?.followers)}
                      {formatSi(user?.followers)} Followers
                    </Typography>
                    <Typography fontWeight="bold">
                      {formatCount(views)}
                      {formatSi(views)} Views
                    </Typography>
                    <Typography fontWeight="bold">
                      {formatCount(likes)}
                      {formatSi(likes)} Likes
                    </Typography>
                  </Stack>

                  {userInfo.username === username && (
                    <Stack direction="row" gap={2}>
                      <Button
                        variant="outlined"
                        onClick={() => setEnableEditProfile(true)}
                      >
                        Edit Profile
                      </Button>
                    </Stack>
                  )}

                  {userInfo.username !== username && (
                    <Stack direction="row" gap={2}>
                      <Button
                        onClick={() => {
                          if (!setSelectedChat) {
                            return;
                          }
                          setSelectedChat({
                            chatFrom: username,
                            chatTo: userInfo.username,
                            createdAt: '',
                            fcmToken: '',
                            name: user?.name,
                            profilePic: user?.profilePic,
                            roomname: userInfo.username + username,
                            seen: 0,
                            shared_public_key: '',
                            threadId: '',
                            chat: null,
                          });
                          setMessages(null);
                          setPage(1);
                          setTabValue(0);
                        }}
                        variant="contained"
                      >
                        Message
                      </Button>
                      <Button onClick={handleUnfollow} variant="outlined">
                        Following
                      </Button>
                      <IconButton
                        onClick={() => {
                          setShowMoreMenu((prev) => !prev);
                        }}
                      >
                        <MoreVertOutlined />
                      </IconButton>
                      {showMoreMenu && (
                        <Card
                          onMouseLeave={() => setShowMoreMenu(false)}
                          sx={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px',
                            right: 0,
                            top: '55px',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Button
                            variant="text"
                            sx={{ textAlign: 'left' }}
                            onClick={handleBlockUser}
                          >
                            Block
                          </Button>
                          <Button
                            variant="text"
                            sx={{ textAlign: 'left' }}
                            onClick={handleUnfollow}
                          >
                            Unfollow
                          </Button>
                        </Card>
                      )}
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Stack direction="column">
                  <Stack direction="row" gap={2}>
                    <Stack direction="row" gap={2}>
                      <Typography fontWeight="bold">
                        {formatCount(user?.followers)}
                        {formatSi(user?.followers)} Followers
                      </Typography>
                      <Typography fontWeight="bold">
                        {formatCount(views)}
                        {formatSi(views)} Views
                      </Typography>
                      <Typography fontWeight="bold">
                        {formatCount(likes)}
                        {formatSi(likes)} Likes
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" gap={1} mt={1}>
                    <Button onClick={handleMessageRequest} variant="contained">
                      Follow
                    </Button>

                    <Button variant="outlined" disabled>
                      Message
                    </Button>
                  </Stack>
                </Stack>
              )}
            </div>
          </div>

          <div style={{ marginTop: 20, marginBottom: '100px' }}>
            <Typography variant="body2">{user.bio}</Typography>

            <List>
              {userLinks
                .slice(0, showMoreLinks ? userLinks.length : 3)
                .map((link) => (
                  <ListItem
                    sx={{ padding: '0px' }}
                    button
                    component="a"
                    href={link.link}
                    target="_blank"
                    key={link.link}
                  >
                    <ListItemText primary={link.label} secondary={link.link} />
                  </ListItem>
                ))}
              {userLinks.length > 3 && (
                <Button onClick={() => setShowMoreLinks(!showMoreLinks)}>
                  {showMoreLinks
                    ? 'Show Less'
                    : `Show More (${userLinks.length - 3})`}
                </Button>
              )}
            </List>
            <h2>Posts</h2>
            <InfiniteScroll
              scrollThreshold={0.7}
              height={'calc(100vh)'}
              dataLength={posts.length}
              next={() => setPageIndex((prev) => prev + 1)}
              hasMore={hasMore}
              loader={
                <Box display="flex" justifyContent="center" my={4}>
                  <CircularProgress />
                </Box>
              }
              endMessage={
                <Typography
                  variant="body2"
                  align="center"
                  color="textSecondary"
                >
                  No more results
                </Typography>
              }
            >
              <Grid container spacing={2}>
                {posts.map((post, index) => {
                  const fontFamilyStyle =
                    post?.fontFamilyStyle && post.fontFamilyStyle.includes('{')
                      ? JSON.parse(post.fontFamilyStyle)
                      : null;
                  return (
                    <Grid item xs={12} sm={6} md={3} key={post.id}>
                      <Card
                        onClick={() => {
                          setOpenPost(true);
                          setPostData(post);
                        }}
                        style={{
                          margin: 'auto',
                          width: '100%',
                          position: 'relative',
                          height: '400px',
                          overflow: 'hidden',
                          display: 'flex',
                          padding: '0px',
                          flexDirection: 'column',
                          backgroundColor:
                            fontFamilyStyle && fontFamilyStyle.bgColor,
                        }}
                      >
                        {post.isRepost && (
                          <Chip
                            style={{
                              position: 'absolute',
                              top: '10px',
                              right: '10px',
                              color: '#fff',
                            }}
                            variant="outlined"
                            size="small"
                            label="Reposted"
                            color="#fff"
                          />
                        )}
                        {post.mimeType && (
                          <CardMedia
                            {...(post.mimeType?.includes('video') && {
                              preload: 'none', // Prevent video from preloading
                            })}
                            component={
                              post.mimeType?.includes('video') ||
                              post.mimeType?.includes('audio')
                                ? 'video'
                                : 'img'
                            }
                            ref={(el) => (videoRefs.current[index] = el)}
                            src={`https://soapboxapi.megahoot.net/images/${post.image}`}
                            controls={true}
                            alt={post.caption}
                            sx={{
                              objectFit: 'cover',
                              height: 400,

                              width: '100%',
                            }}
                            poster={
                              post?.mimeType?.includes('audio')
                                ? `/audio.png`
                                : post?.mimeType?.includes('video')
                                ? getVideoPoster(post.image)
                                : null
                            }
                            draggable={false}
                            onMouseEnter={(e) => {
                              if (post.mimeType?.includes('video')) {
                                e.target.play();
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (post.mimeType?.includes('video')) {
                                e.target.pause();
                                e.target.currentTime = 0; // Optional: reset video to start
                              }
                            }}
                            muted
                          />
                        )}

                        <CardContent style={{ padding: '0px', width: '100%' }}>
                          {/* <Typography variant="body2" color="textSecondary">
                          {post.caption}
                        </Typography> */}
                          <MarkdownPreview
                            source={post.caption}
                            style={
                              fontFamilyStyle
                                ? {
                                    all: 'unset',
                                    padding: 10,
                                    wordWrap: 'break-word',
                                    width: '100%',
                                    overflow: 'auto',
                                    height: '100%',
                                    color: fontFamilyStyle?.color,
                                    backgroundColor: fontFamilyStyle?.bgColor,
                                  }
                                : {
                                    padding: 4,
                                    wordWrap: 'break-word',
                                    maxWidth: '100%',
                                    overflow: 'auto',
                                    maxHeight: '200px',
                                  }
                            }
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          </div>
        </div>
      )}

      {channelData && (
        <Paper>
          <Grid container alignItems="center" sx={{ p: 3 }}>
            <CardContent>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item>
                  <Avatar
                    src={`http://soapboxapi.megahoot.net/profile-pictures/${channelData?.image}`}
                    alt={channelData?.name}
                    sx={{ height: '60px', width: '60px' }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    {channelData?.name}
                  </Typography>
                  <Typography color="textSecondary">
                    @{channelData?.username}
                  </Typography>
                </Grid>
              </Grid>

              <Box sx={{ mt: 3 }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  {channelData?.description}
                </Typography>
                <Stack spacing={1}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Total Members:</strong>{' '}
                    {channelData?.members
                      ? JSON.parse(channelData?.members).length
                      : 0}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Grid>
        </Paper>
      )}

      {postData && postData.id && (
        <Dialog
          open={openPost}
          onClose={() => {
            setOpenPost(false);
            setPostData(null);
          }}
          maxWidth="md"
        >
          <IndividualPostOverlay
            postData={postData}
            setPosts={setPosts}
            forceClose={() => {
              setOpenPost(false);
              setPostData(null);
            }}
          />
        </Dialog>
      )}
      {enableEditProfile && userInfo.username === username && (
        <Dialog
          open={enableEditProfile}
          onClose={() => {
            setEnableEditProfile(false);
          }}
          maxWidth="md"
        >
          <ProfileEditScreen />
          {/* <IndividualPostOverlay postData={postData} /> */}
        </Dialog>
      )}
    </div>
  );
};

export default PublicProfilePage;
